<template>
  <div class='top-info-block'>
    <inline-svg :src='Ellipse1' class='ellipse_1'/>
    <div class='left-block'>
      <div class='main-text'> christmas<br/>portfolio battle 2.0 <inline-svg :src='RedHat' class='red-hat'/></div>
      <div class='secondary-text'>FANTASY LEAGUE WITH ZERO INVESTMENTS</div>
      <div class='description'>Create a virtual portfolio, earn the most in 7 days, and claim a piece of the $1000 reward pool.</div>
      <div>
        <div class='start-date'>Start Date: {{ getStartDate }} - END DATE: {{ getEndDate }}</div>
        <join-button/>
      </div>
    </div>

    <div class='right-block'>
      <img :src='Portfolio' class='portfolio'/>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'
import { Ellipse1, RedHat } from '../assets/svg'
import { Portfolio } from '../assets/png'

const startDate = inject('startDate', 0)
const endDate = inject('endDate', 0)

const getStartDate = computed(() => (new Date(startDate.value)).toLocaleString('uk-UA').split(',')[0])
const getEndDate = computed(() => (new Date(endDate.value)).toLocaleString('uk-UA').split(',')[0])
</script>