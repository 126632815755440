import {instance} from './instance'

export const getUsersInfo = async () => {
    try {
        const resp = await instance.get('/portfolio/battle/info')
        return {
            registered_today: resp?.data?.result.registered_today || 0,
            registered_total: resp?.data?.result.registered_total || 0,
        }
    } catch (e) {
        return {
            registered_today: 0,
            registered_total: 0,
        }
    }
}

export const getInfo = async () => {
    try {
        const resp = await instance.get('/portfolio/battles')
        return {
            start_date: +new Date(resp?.data?.result.start_date) || 0,
            end_date: +new Date(resp?.data?.result.end_date) || 0,
            now_date: +new Date(resp?.data?.result.now_date) || 0,
        }
    } catch (e) {
        return {
            start_date: 0,
            now_date: 0,
            end_date: 0,
        }
    }
}

export const getIcons = async () => {
    try {
        const {data: {result}} = await instance.get('/cryptocurrency')
        return result
    } catch (e) {
        return []
    }
}