<template>
  <div class='how-to-join' id='howToJoin'>
    <div class='title'>
      HOW TO JOIN THE WAITLIST
    </div>
    <inline-svg :src='StepsBg_1' class='steps-bg-1'/>
    <img :src='StepsBg_3' class='steps-bg-3'/>
    <inline-svg :src='StepsBg_4' class='steps-bg-4'/>
    <div class='collapse-list'>
      <collapse
          title='1. Join Wallmer Telegram Bot'
          :custom-key='key'
          :is-open='openedCollapse === "tg"'
          :is-checked='collapseData.tgChecked'
          @click='_ => openCollapse("tg")'
      >
        <div class='collapse-content' @click.stop>
          <img :src='TgBot'/>
          <ol class='form'>
            <li>Open Telegram app on your mobile device or desktop</li>
            <li>Write “/start” and hit Send button</li>
            <button class='btn' @click='openTgBot'>Open Telegram Bot</button>
            <li>Enter your Telegram name</li>
            <div class='input-field'>
              <input v-model='tgName' placeholder='Telegram Name' :disabled='collapseData.tgChecked'/>
              <button class='btn' @click='verifyTg'>Verify</button>
            </div>
          </ol>
        </div>
      </collapse>
      <collapse
          title='2. Create account on Wallmer platform'
          :custom-key='key'
          :is-open='openedCollapse === "email"'
          :is-checked='collapseData.emailChecked'
          @click='_ => openCollapse("email")'
      >
        <div class='collapse-content' @click.stop>
          <img :src='CreateAccount'/>
          <ol class='form'>
            <li>Sign up on Wallmer Platform</li>
            <button class='btn' @click='_ => goTo(SIGNUP)'>Create Account</button>
            <li>Check your email and confirm registration</li>
            <li>Enter your Wallmer account Email</li>
            <div class='input-field'>
              <input v-model='email' placeholder='username@mail.com' :disabled='collapseData.emailChecked'/>
              <button class='btn' @click='verifyEmail'>Verify</button>
            </div>
          </ol>
        </div>
      </collapse>
      <collapse
          title='3. Install Wallmer App'
          :custom-key='key'
          :is-open='openedCollapse === "app"'
          :is-checked='collapseData.appChecked'
          @click='_ => openCollapse("app")'
      >
        <div class='collapse-content install-app' @click.stop>
          <div class='text'>Download and Install Wallmer App</div>
          <div class='buttons'>
            <div class='ios-device' @click='_ => verifyApp(APPSTORE)'>
              I have an iOs device
            </div>
            <img :src='AppStore' class='ios'/>
            <img :src='GPlay' @click='_ => verifyApp(GPLAY)'/>
          </div>
        </div>
      </collapse>
      <collapse
          title='4. Create portfolio on Wallmer with min. value of 1000$ - virtual money'
          :custom-key='key'
          :is-open='openedCollapse === "link"'
          :is-checked='collapseData.linkChecked'
          @click='openCollapse("link")'
      >
        <div class='collapse-content' @click.stop>
          <img :src='CreatePortfolio'/>
          <ul class='form last-block'>
            <li>Go to Portfolio Tracker section on Wallmer</li>
            <li>Press “Add to Portfolio”, choose “Add transaction” <i class='sub-info'>3 - 10 tokens</i></li>
            <li>Fill your virtual portfolio with transactions for the competition <i class='sub-info'>$1000</i></li>
            <div class='submit-link'>
              <div class='text'>Submit the link to the Wallmer portfolio you've made for the competition</div>
              <div class='input-field'>
                <input v-model='link' placeholder='Press “Save” in the pop up, copy and paste portfolio link'/>
              </div>
              <div class='buttons-group'>
                <button class='btn' @click='linkVerify'>Verify</button>
                <button class='btn info' @click='_ => goTo(WHERE_LINK)'>
                  Where is my portfolio link?
                  <inline-svg :src='Play'/>
                </button>
              </div>
            </div>
          </ul>
        </div>
      </collapse>
      <collapse
          title='5. Make it public and share your link with friends to gain followers'
          :custom-key='key'
          :is-open='openedCollapse === "makePublic"'
          :is-checked='collapseData.makePublic'
          @click='openCollapse("makePublic")'
      >
        <div class='collapse-content' @click.stop>
          <img :src='MakePublic'/>
          <ul class='form last-block'>
            <li>Go to Portfolio Tracker section on Wallmer</li>
            <li>Choose the portfolio that you created for the battle, press “Share”</li>
            <li>Enter your profile name, copy the link for sharing and press “Save”</li>
            <div class='submit-link'>
              <div class='text italic'>The link to your Wallmer portfolio is in the pop up, copy and share it with your
                friends. Be the first from 25 to gain more than 50 followers and get a guaranteed prize - 20$.
              </div>
              <div class='buttons-group'>
                <button class='btn' @click='makePublicClick'>Got it</button>
                <button class='btn info' @click='_ => goTo(WHERE_LINK)'>
                  Where is my portfolio link?
                  <inline-svg :src='Play'/>
                </button>
              </div>
            </div>
          </ul>
        </div>
      </collapse>
    </div>
    <button class='join-button' :class='{block: !isButtonActive}' @click='joinClick'>
      Join Waitlist
    </button>
  </div>
</template>

<script setup>
import {toast} from 'vue3-toastify'
import {TgBot, AppStore, GPlay, CreateAccount, CreatePortfolio, MakePublic} from '../assets/png'
import {Play, StepsBg_1, StepsBg_3, StepsBg_4} from '../assets/svg'
import {
  TG_LINK,
  EMAIL_REGEXP,
  SIGNUP,
  APPSTORE,
  GPLAY,
  WHERE_LINK
} from '../core/values'
import {verify, join} from '../core/https'

import {ref, reactive, onMounted, computed} from 'vue'

const tgName = ref('')
const email = ref('')
const link = ref('')

const openedCollapse = ref('tg')
const key = ref(0)

const collapseData = reactive({
  tgChecked: false,
  emailChecked: false,
  appChecked: false,
  linkChecked: false,
  makePublic: false,
})

const isButtonActive = computed(() => Object.values(collapseData).every(item => item))

onMounted(() => {
  const savedInfo = JSON.parse(localStorage.getItem('saved_info'))
  if (!savedInfo) {
    localStorage.setItem('saved_info', '{}')
    return
  } else {
    tgName.value = savedInfo?.tg_name || ''
    collapseData.tgChecked = !!savedInfo?.tg_name || false
    email.value = savedInfo?.email || ''
    collapseData.emailChecked = !!savedInfo?.email || false
    collapseData.appChecked = !!savedInfo?.app || false
    link.value = savedInfo?.link || ''
    collapseData.linkChecked = !!savedInfo?.link || false
    collapseData.makePublic = !!savedInfo?.makePublic || false
  }
  setLastStep()
})

const goTo = (link) => {
  window.open(link, '_target')
}

const notify = (text, type = 'success') => {
  toast(text, {
    autoClose: 5000,
    transition: 'slide',
    hideProgressBar: true,
    theme: 'colored',
    type
  });
}

const openCollapse = (name) => {
  key.value++
  if (openedCollapse.value === name) {
    openedCollapse.value = ''
    return
  }
  openedCollapse.value = name
}

const setLastStep = () => {
  if (collapseData.makePublic) return
  else if (collapseData.linkChecked) openCollapse('makePublic')
  else if (collapseData.appChecked) openCollapse('link')
  else if (collapseData.emailChecked) openCollapse('app')
  else if (collapseData.tgChecked) openCollapse('email')
  else openCollapse('tg')
}

const openTgBot = () => {
  window.open(TG_LINK, '_blank', 'noreferrer')
}

const verifyTg = async () => {
  if (!tgName.value) return
  if (collapseData.tgChecked) return openCollapse('email')
  const isVerify = await verify('nickname', tgName.value)
  if (isVerify.result) {
    const savedInfo = JSON.parse(localStorage.getItem('saved_info'))
    savedInfo.tg_name = tgName.value
    localStorage.setItem('saved_info', JSON.stringify(savedInfo))
    collapseData.tgChecked = true
    openCollapse('email')
  } else {
    notify('Invalid Telegram username. Please enter a valid username.', 'error')
  }
}

const verifyEmail = async () => {
  if (!email.value) return
  if (collapseData.emailChecked) return openCollapse('app')
  if (!RegExp(EMAIL_REGEXP).test(email.value)) {
    notify('Incorrect email format. Please check your email address', 'error')
    return
  }
  const isVerify = await verify('email', email.value)
  if (isVerify.result) {
    const savedInfo = JSON.parse(localStorage.getItem('saved_info'))
    savedInfo.email = email.value
    localStorage.setItem('saved_info', JSON.stringify(savedInfo))
    collapseData.emailChecked = true
    openCollapse('app')
  } else {
    notify('nvalid email address. Please enter a valid email.', 'error')
  }
}

const verifyApp = async (app) => {
  if (collapseData.appChecked) return openCollapse('link')
  if (app !== APPSTORE) goTo(app)
  const savedInfo = JSON.parse(localStorage.getItem('saved_info'))
  savedInfo.app = true
  localStorage.setItem('saved_info', JSON.stringify(savedInfo))
  collapseData.appChecked = true
  openCollapse('link')
}

const linkVerify = async () => {
  if (!link.value) return
  const isVerify = await verify('portfolio', link.value)
  if (isVerify.result) {
    const savedInfo = JSON.parse(localStorage.getItem('saved_info'))
    savedInfo.link = link.value
    localStorage.setItem('saved_info', JSON.stringify(savedInfo))
    collapseData.linkChecked = true
    openCollapse('makePublic')
  } else if (isVerify.message) notify(isVerify.message, 'error')
  else notify('Verification steps not completed. Please complete the verification process before clicking \'Join Waitlist\'', 'error')
}

const makePublicClick = () => {
  const savedInfo = JSON.parse(localStorage.getItem('saved_info'))
  savedInfo.makePublic = true
  localStorage.setItem('saved_info', JSON.stringify(savedInfo))
  openCollapse('makePublic')
  collapseData.makePublic = true
}

const joinClick = async () => {
  if (!isButtonActive.value) return
  const isVerify = await join({
    nickname: tgName.value,
    url: link.value,
    email: email.value
  })

  if (isVerify.result) {
    notify('Congratulations, you\'ve officially joined waitlist of the Christmas Portfolio Battle 2.0!')
    clearData()
    openCollapse('tg')
  } else if (isVerify.message) {
    notify(isVerify.message, 'error')
  } else notify('Invalid link format. Please provide a valid portfolio URL.', 'error')
}

const clearData = () => {
  tgName.value = ''
  email.value = ''
  link.value = ''

  collapseData.tgChecked = false
  collapseData.emailChecked = false
  collapseData.appChecked = false
  collapseData.linkChecked = false

  localStorage.setItem('saved_info', '{}')
}

</script>