<template>
  <div class='footer'>
    <div class='links'>
      <div class='link-block'>
        <div class='title'>Company</div>
        <a
          :href='LOGIN'
          target='_blank'
          rel='noreferrer noopener'
        >
          Login
        </a>
        <a
          :href='SIGNUP'
          target='_blank'
          rel='noreferrer noopener'
        >
          Sign Up
        </a>
        <a
          :href='HOW_IT_WORKS'
          target='_blank'
          rel='noreferrer noopener'
        >
          How it works
        </a>
        <a
          :href='BLOG'
          target='_blank'
          rel='noreferrer noopener'
        >
          Blog
        </a>
      </div>
      <div class='link-block'>
        <div class='title'>For partners</div>
        <a
          :href='SUBMIT_DAPP'
          target='_blank'
          rel='noreferrer noopener'
        >
          Submit a dApp
        </a>
        <a
          :href='SUBMIT_TOKEN'
          target='_blank'
          rel='noreferrer noopener'
        >
          Submit a Token
        </a>
        <a
          :href='SUBMIT_PARTNERSHIP'
          target='_blank'
          rel='noreferrer noopener'
        >
          Submit a Partnership
        </a>
      </div>
      <div class='link-block'>
        <div class='title'>Legal</div>
        <a
          :href='TERMS_OF_USE'
          target='_blank'
          rel='noreferrer noopener'
        >
          Terms and condition
        </a>
        <a 
          :href='PRIVACY_POLICY'
          target='_blank'
          rel='noreferrer noopener'
        >
          Privacy Policy
        </a>
      </div>
      <div class='link-block'>
        <div class='title'>Contact support</div>
        <a :href='"mailto:"+ SUPPORT_EMAIL'>Email</a>
      </div>
      <div class='link-block'>
        <div class='title'>Follow us</div>
        <div class='icons'>
          <!-- <inline-svg :src='icons.Instagram'/> -->
          <inline-svg :src='icons.Twitter' @click='goTo(TWITTER_WALLMER)'/>
          <!-- <inline-svg :src='icons.Discord'/> -->
          <inline-svg :src='icons.Youtube' @click='goTo(YOUTUBE_WALLMER)'/>
          <!-- <inline-svg :src='icons.Facebook'/> -->
          <inline-svg :src='icons.Telegram' @click='goTo(TELEGRAM_WALLMER)'/>
          <!-- <inline-svg :src='icons.Reddit'/> -->
        </div>
      </div>
    </div>
    <div class='copyright'>
      Copyright © 2023 Wallmer. All rights reserved.
    </div>
    <inline-svg :src='Snowdrift' class='snowdrift'/>
  </div>
</template>

<script setup>
import icons from '../assets/svg/icons'
import { Snowdrift } from '@/assets/svg';
import {
  LOGIN,
  SIGNUP,
  SUBMIT_DAPP,
  SUBMIT_TOKEN,
  SUBMIT_PARTNERSHIP,
  TERMS_OF_USE,
  PRIVACY_POLICY,
  HOW_IT_WORKS,
  BLOG,
  SUPPORT_EMAIL,
  TWITTER_WALLMER,
  TELEGRAM_WALLMER,
  YOUTUBE_WALLMER 
} from '../core/values'

const goTo = (link) => {
  window.open(link, '_target')
}
</script>