<template>
  <div class='faq'>
    <div class='title'>FAQ</div>
    <inline-svg :src='Ellipse5' class='ellipse-5'/>
    <inline-svg :src='FaqBg_1' class='faq-bg-1'/>
    <inline-svg :src='FaqBg_2' class='faq-bg-2'/>
    <inline-svg :src='FAQ' class='faq-icon'/>
    <div class='faq-list'>
      <faq-collapse 
        v-for='question in questions' 
        :key='question.title' 
        :title='question.title'
      >
        <div class='content' v-html='question.answ'/>
      </faq-collapse>

      <faq-collapse title='Can I be Disqualified?'>
        <div class='content'>
          Yes, any violation of the rules means automatic disqualification.<br/>
          -  You have more than 1 portfolio per user<br/>
          -  You deleted Wallmer app before the end of the Battle<br/>
          -  You deleted Telegram bot before the end of the Battle<br/>
          -  You made changes to your portfolio before the end of the Battle<br/>
          -  Your portfolio balance amount doesn't comply with the rules<br/>
          -  The amount of tokens in your portfolio  and their market cap  don't comply with the rules<br/>
          You can also be disqualified and denied any prize money if the Wallmer Team finds that you  tried to manipulate the competition results in any way
        </div>
      </faq-collapse>

      <faq-collapse title='How the Prize Pool is shared?'>
        <div class='content'>
          Users are judged based on their portfolio performance.The prize pool of $1000  is shared this way:
          <div class='prizes'>
            <div>
              <p>1st place  - $100</p>
              <p>2st place  - $75</p>
              <p>3st place  - $50</p>
            </div>
            <div>
              <p>4-5 places - $25</p>
              <p>6-9 place - $20</p>
            </div>
            <div>
              <p>10-19 places - $10</p>
              <p>20-25 place - $5</p>
            </div>
          </div>
        </div>
      </faq-collapse>

      <faq-collapse title='How do I get my Rewards?'>
        <div class='content'>
          Winners will be notified via Wallmer Telegram bot and rewarded within 5 working days after the battle ends
        </div>
      </faq-collapse>
    </div>
  </div>
</template>

<script setup>
import { FAQ, FaqBg_1, FaqBg_2, Ellipse5 } from '../assets/svg'
import { reactive } from 'vue'
const questions = reactive([
  { title: 'Is participation in Fantasy Portfolio Battle FREE?', answ: 'Yes, the participation is totally free, no need to invest anything.' },
  { title: 'How do I choose tokens to my Portfolio?', answ: 'You can choose only tokens with <b>more than $3M</b> Market capitalization. Minimum tokens in portfolio should be 3, maximum  - 10' },
  { title: 'When can I join?', answ: 'You can join the battle any time <b>before</b> December 26, 2023' },
  { title: 'Can I make changes to my portfolio?', answ: 'You can make changes as many times as you wish <b>BEFORE</b> the start of the competition. After the start of the Battle changes <b>are NOT permitted</b>.' }
])
</script>