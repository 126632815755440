<template>
  <layout>
    <div id="snow"></div>
    <top-info/>
    <secondary-info/>
    <Leaderboard v-if='isShowLeaderboard'/>
    <pre-competition/>
    <how-to-join v-if="!isShowLeaderboard"/>
    <FAQ/>
    <bottom-info/>
  </layout>
</template>

<script setup>
import { ref, provide } from 'vue'
import { snowStart } from './core/PureSnow'
import { onMounted } from 'vue'
import { getInfo } from './core/https/get'

const startDate = ref(0)
const endDate = ref(0)
const isShowLeaderboard = ref(false)

onMounted(async () => {
  snowStart()
  const { start_date, end_date, now_date } = await getInfo()
  startDate.value = start_date
  endDate.value = end_date
  isShowLeaderboard.value = now_date >= start_date
});

provide('startDate', startDate)
provide('endDate', endDate)
</script>
