import Instagram from './instagram.svg';
import Facebook from './facebook.svg';
import Twitter from './twitter.svg';
import Telegram from './telegram.svg';
import Reddit from './reddit.svg';
import Discord from './discord.svg';
import Youtube from './youtube.svg';

export default {
    Instagram, Facebook, Twitter, Telegram, Reddit, Youtube, Discord
}