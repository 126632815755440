export const TG_LINK = process.env.VUE_APP_TG_BOT_LINK

export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const LOGIN = process.env.VUE_APP_LOGIN
export const SIGNUP = process.env.VUE_APP_SIGNUP
export const MAIN = process.env.VUE_APP_MAIN

export const APPSTORE = process.env.VUE_APP_APPSTORE
export const GPLAY = process.env.VUE_APP_GPLAY
export const WHERE_LINK = process.env.VUE_APP_WHERE_LINK


export const SUBMIT_DAPP = process.env.VUE_APP_SUBMIT_DAPP
export const SUBMIT_TOKEN = process.env.VUE_APP_SUBMIT_TOKEN
export const SUBMIT_PARTNERSHIP = process.env.VUE_APP_SUBMIT_PARTNERSHIP
export const TERMS_OF_USE = process.env.VUE_APP_TERMS_OF_USE
export const PRIVACY_POLICY = process.env.VUE_APP_PRIVACY_POLICY
export const HOW_IT_WORKS = process.env.VUE_APP_HOW_IT_WORKS
export const BLOG = process.env.VUE_APP_BLOG
export const SUPPORT_EMAIL = process.env.VUE_APP_SUPPORT_EMAIL
export const TWITTER_WALLMER = process.env.VUE_APP_TWITTER_WALLMER
export const TELEGRAM_WALLMER = process.env.VUE_APP_TELEGRAM_WALLMER
export const YOUTUBE_WALLMER = process.env.VUE_APP_YOUTUBE_WALLMER