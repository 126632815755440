import { createApp } from 'vue'
import InlineSvg from 'vue-inline-svg'
import App from "./App.vue"
import '@/styles/index.scss'

import NavBar from "./components/navBar.vue"
import Layout from "./components/layout.vue"
import Footer from "./components/footer.vue"
import TopInfo from "./components/topInfo.vue"
import BottomInfo from './components/bottomInfo.vue'
import SecondaryInfo from './components/secondaryInfo.vue'
import PreCompetition from './components/preCompetition.vue'
import HowToJoin from './components/howToJoin.vue'
import Collapse from './components/collapse.vue'
import FAQ from './components/faq.vue'
import FaqCollapse from './components/faqCollapse.vue'
import JoinButton from './components/joinButton.vue'
import Leaderboard from './components/Leaderboard.vue'

const app = createApp(App)

app.component('inline-svg', InlineSvg)

app.component('NavBar', NavBar)
    .component('Layout', Layout)
    .component('Footer', Footer)
    .component('TopInfo', TopInfo)
    .component('BottomInfo', BottomInfo)
    .component('SecondaryInfo', SecondaryInfo)
    .component('PreCompetition', PreCompetition)
    .component('HowToJoin', HowToJoin)
    .component('Collapse', Collapse)
    .component('FAQ', FAQ)
    .component('FaqCollapse', FaqCollapse)
    .component('JoinButton', JoinButton)
    .component('Leaderboard', Leaderboard)

app.mount("#app")