<template>
  <button class='join-button' @click='click'>
    Join Waitlist
  </button>
</template>

<script setup>
const click = () => {
  // const savedData = JSON.parse(localStorage.getItem('saved_info'))
  // if (!savedData.email || !savedData.tg_name || !savedData.link) 
  // return 
  document.getElementById('howToJoin').scrollIntoView({ behavior: "smooth" })
}
</script>