<template>
  <div class='vcp custom-collapse' :class='{checked: props.isChecked}'>
    <vue-collapsible-panel :expanded="props.isOpen" ref='collapse'>
      <template #title>
            {{ props.title }}
        </template>
        <template #content>
            <slot/>
        </template>
    </vue-collapsible-panel>
    <div class='check-icon'>
      <inline-svg :src='Check'/>
    </div>
  </div>
</template>

<script setup>
import { VueCollapsiblePanel } from '@dafcoe/vue-collapsible-panel'
import { Check } from '../assets/svg'
import { ref, defineProps, watch, nextTick } from 'vue'

const props = defineProps({
  title: {
    type: String,
    default: 'Title'
  },
  isOpen: {
    type: Boolean,
    default: false
  },
  isChecked: {
    type: Boolean,
    default: false,
  },
  customKey: {
    type: Number,
    default: 0
  },
})

const collapse = ref()

watch(() => props.customKey, () => {
  const isExpanded = collapse?.value?.isExpanded
  nextTick(() => {
    if(!props.isOpen && isExpanded) collapse?.value?.toggle()
    else if (props.isOpen && !isExpanded) collapse?.value?.toggle()
  })
})

</script>