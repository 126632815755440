<template>
  <div class='leaderboard'>
    <div class='title'>Intermediate results</div>
    <div class='content'>
      <div class='block-name'>Leaderboard</div>
      <!-- <b-loading :active='isLoaded' :is-full-page="false" class='exchange-loader'/> -->
      <div class='table'>
        <loading
            v-model:active="isLoading"
            :is-full-page="false"
            color='#3571FD'
        />
        <table class='custom-table'>
          <thead class='custom-table-head'>
          <th class='rank'>#</th>
          <th class='email'>Email</th>
          <th class='email'>Followers</th>
          <th class='tokens'>Tokens</th>
          <th class='balance'>Balance</th>
          <th class='header'>PnL</th>
          </thead>
          <tbody v-show='tableData.users.length' class='custom-table-body'>
          <tr
              v-for='row in tableData.users'
              :key='row.email'
              class='row'
              :class='{"top-pos": row.rank <= 3}'
          >
            <td class='rank'>{{ row.rank }}</td>
            <td class='email'>
              <div class="email-link">
                {{ row.email }}
                <a
                    :href='row.url'
                    target='_blank'
                    class="link-to"
                >
                  <inline-svg :src='LinkTo'/>
                </a>
              </div>
            </td>
            <td class='email'>{{ row.followers }}</td>
            <td class='tokens'>
              <div class='token-list' :style='{"--length": row.tokens.length}'>
                <img
                    v-for='token, index in row.tokens'
                    :key='token + index'
                    :src='getTokenIcon(token) || ""'
                    class='token'
                    :style='{"--n": index}'
                />
              </div>
            </td>
            <td class='balance'>{{ priceFormat(row.balance) }}</td>
            <td class='pnl'>
              <div v-if='row.pnl_percent' class='pnl-content'
                   :class='row.pnl_percent_increase ? "increase" : "decrease"'>
                <inline-svg :src='ArrowUp' :class='row.pnl_percent_increase ? "increase-icon" : "decrease-icon"'/>
                {{ (row.pnl_percent_increase ? '+ ' : '') + row.pnl_percent }}%
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <div v-show='!tableData.users.length' class='empty'>
          Empty
        </div>
      </div>
    </div>
    <div v-if='isPaginated' class='pagination'>
      <v-pagination
          v-model='page'
          :pages="pagination.total"
          active-color='#3571FD'
          hide-first-button
          hide-last-button
          @update:modelValue='changePage'
      />
    </div>
  </div>
</template>

<script setup>
import VPagination from "@hennge/vue3-pagination"
import Loading from 'vue-loading-overlay'
import {
  getTableData,
  getIcons
} from '../core/https'
import ArrowUp from '../assets/svg/icons/arrowUp.svg'
import {reactive, ref, computed, onBeforeMount} from 'vue'
import {LinkTo} from "@/assets/svg";

const tableData = reactive({
  users: []
})
let icons = []
const pagination = reactive({
  page: 1,
  total: 0
})
const isLoading = ref(true)
const page = ref(1)

const isPaginated = computed(() => pagination.total > 1)

onBeforeMount(async () => {
  const iconList = await getIcons()
  icons = iconList
  const data = await getTableData(1)
  tableData.users = data.list
  pagination.total = Math.ceil(data.count / 10)
  isLoading.value = false
})

const getTokenIcon = (token) => {
  return icons[token.toUpperCase()]?.image_url
}

const priceFormat = price => {
  if (!price) return '$0.00'
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price);

}

const changePage = async page => {
  if (page === pagination.page) return
  isLoading.value = true
  pagination.page = page
  const data = await getTableData(page)
  tableData.users = data.list
  isLoading.value = false
}
</script>