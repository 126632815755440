<template>
  <div class='second-info-block'>
    <inline-svg :src='Ellipse2' class='ellipse-2'/>
    <inline-svg :src='Ellipse3' class='ellipse-3'/>
    <div class='registration-period' >
      <div class='title'>{{ blockTitle }}</div>
      <div class='timer' :class='{ended: status === STATUSES.ENDED}'>
        <div class='item'>
          <div class='time'>{{ ('0' + counter.days).slice(-2) }}</div>
          <div class='label'>days</div>
        </div>
        <div class='item'>
          <div class='time'>{{ ('0' + counter.hours).slice(-2) }}</div>
          <div class='label'>hours</div>
        </div>
        <div class='item'>
          <div class='time'>{{ ('0' + counter.minutes).slice(-2) }}</div>
          <div class='label'>minutes</div>
        </div>
        <div class='item'>
          <div class='time'>{{ ('0' + counter.seconds).slice(-2) }}</div>
          <div class='label'>seconds</div>
        </div>
      </div>
    </div>
    <div class='registration-period users-joined'>
      <div class='title'>Users Joined</div>
      <div class='users-count'>
        <div class='counter'>
          {{ users.registered_total }}
        </div>
        <div v-if='status === STATUSES.REGISTRATION && users.registered_today' class='new-users'>
          <div class='amount'>+{{ users.registered_today }}</div>
          today
        </div>
        <inline-svg :src='UsersJoined' class='icon'/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Ellipse2, UsersJoined, Ellipse3 } from '../assets/svg';
import { ref, reactive, computed , onMounted } from 'vue'
import { getUsersInfo, getInfo } from '../core/https'

const STATUSES = {
  ENDED: 'ended',
  REGISTRATION: 'registration',
  STARTING: 'starting',
}

const counter = reactive({
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0
})
const users = reactive({
  registered_today: 0,
  registered_total: 0,
})
const timePoint = ref(null)
const status = ref(STATUSES.REGISTRATION)
const startDate = ref(0)
const endDate = ref(0)
const nowDate = ref(0)

const timer = ref(null)

const blockTitle = computed(() => {
  switch(status.value) {
    case STATUSES.ENDED: return 'Battle Ended'
    case STATUSES.REGISTRATION: return 'Registration Period'
    default: return 'Battle Ends in '
  }
})

onMounted(async () => {
  await getTimeInfo()

  const usersInfo = await getUsersInfo()
  users.registered_total = usersInfo.registered_total

  const today = nowDate.value
  timePoint.value = startDate.value
  if (today > endDate.value || Date.now() > endDate.value) {
    status.value = STATUSES.ENDED
    return
  } else if (today > startDate.value) {
    status.value = STATUSES.STARTING
    timePoint.value = endDate.value
  }

  users.registered_today = usersInfo.registered_today
  timerWork()

  timer.value = setInterval(() => timerWork(), 1000)
})

const timerWork = () => {
  let temp = Math.abs(timePoint.value - nowDate.value) / 1000;
  const days = Math.floor(temp / 86400);
  temp -= days * 86400;
  const hours = Math.floor(temp / 3600) % 24;
  temp -= hours * 3600;
  const minutes = Math.floor(temp / 60) % 60;
  temp -= minutes * 60;
  const seconds = (temp % 60).toFixed(0)
  counter.days = days
  counter.hours = hours
  counter.minutes = minutes
  counter.seconds = seconds
  if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
    if(status.value === STATUSES.STARTING ) {
      status.value = STATUSES.ENDED
      clearInterval(timer.value)
      return
    }
    status.value = STATUSES.STARTING
    timePoint.value = endDate.value
  }
  nowDate.value += 1000
}

const getTimeInfo = async () => {
  const info = await getInfo()

  startDate.value = info.start_date
  endDate.value = info.end_date
  nowDate.value = info.now_date
}

</script>