import {instance} from './instance'

export const verify = async (type, value) => {
    try {
        const resp = await instance.post('/portfolio/battle/verify', {type, value})
        if (resp.data.status) {
            return {result: resp?.data?.result}
        }
        return {message: resp?.data?.message}
    } catch (e) {
        return false
    }
}

export const join = async ({nickname, email, url}) => {
    try {
        const resp = await instance.post('/portfolio/battle/join', {
            nickname, email, url
        })
        if (resp.data.status) {
            return {result: resp?.data?.result}
        }
        return {message: resp?.data?.message}
    } catch (e) {
        return false
    }
}

export const getTableData = async (page) => {
    try {
        const {data: {result: {battle_members}}} = await instance.post('/portfolio/battle/board', {
            page,
            limit: 10
        })
        return battle_members
    } catch (e) {
        return []
    }
}