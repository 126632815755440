<template>
  <div class='navbar'>
    <ul class="lightrope">
      <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
    </ul>
    <div class='logo'>
      <inline-svg :src='logo' @click='_ => goTo(MAIN)'/>
    </div>
    <div class='sign-buttons'>
      <div class='btn' @click='_ => goTo(LOGIN)'>Log in</div>
      <div class='btn' @click='_ => goTo(SIGNUP)'> Sign up</div>
    </div>
  </div>
</template>

<script setup>
import logo from '../assets/svg/logo.svg'
import { MAIN, LOGIN, SIGNUP } from '../core/values'

const goTo = (link) => {
  window.open(link, '_target')
}
</script>